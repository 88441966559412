@import "~@fortawesome/fontawesome-free/css/all.css";
html,
body {
    height: 100%;
    width: 100%;
    padding: 0;
    font-family: "rimouski";
    font-size: 14px;
    color: #666;
    background-color: #f7f8fc;
}

@font-face {
    font-family: 'rimouski';
    src: url('assets/fonts/Gilroy-Light.otf');
    font-display: swap;
}

@font-face {
    font-family: 'rimouski-bold';
    src: url('assets/fonts/Gilroy-ExtraBold.otf');
    font-display: swap;
}

@font-face {
    font-family: 'rimouski-light';
    src: url('assets/fonts/Gilroy-Light.otf');
    font-display: swap;
}


/* width */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #4b7ffc;
    height: 5px;
    border-radius: 30px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: #555;
}

.font-normal {
    font-family: 'rimouski';
}

.font-bold {
    font-family: 'rimouski-bold';
}

.font-light {
    font-family: 'rimouski-light';
}


/* footer */

.min-width-355 {
    min-width: 355px;
}

.footer {
    color: #666666;
    background-color: #222222;
    padding-top: 45px;
    padding-bottom: 30px;
}

.li {
    /* a color */
    color: #666666;
}

.title-heading {
    /* title footer */
    display: block;
    font-size: 18px;
    padding: 0 0 25px 0;
    margin: 0;
    font-weight: 700;
    font-family: 'rimouski-bold';
    text-transform: uppercase;
}

.btn-footer:hover {
    color: #222222;
    background-color: #4b7ffc;
    border-color: #4b7ffc;
}

.btn-footer {
    border-style: solid;
    border-width: 1px;
    padding: 0;
    width: 110px;
    height: 45px;
    line-height: 45px;
    border-radius: 0 3px 3px 0;
    color: #ffffff;
    background-color: #4b7ffc;
    border-color: #4b7ffc;
}

.input-footer {
    height: 45px;
    margin: 0;
    width: 230px !important;
    border-width: 1px;
    border-radius: 3px 0 0 3px;
    border-style: solid;
    padding: 0 0 0 24px;
    font-size: 14px;
}

.round-bkg-icon {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 24px;
    font-size: 19px;
    padding: 14px;
    min-width: 46px;
}


/*btns*/

.btn-daclick {
    /* color: #ffffff;
    background-color: #4b7ffc;
    border-color: #4b7ffc;
    padding: 10px 35px;
    font-family: 'rimouski-bold';
    border-radius: 19px; */
    /* display: inline-block;
    padding: 0.46em 1.6em;
    border: 0.1em solid #fff;
    margin: 0 0.2em 0.2em 0;
    border-radius: 10px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #fff;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    background-color: #4b7ffc;
    text-align: center;
    transition: all 0.15s; */
    display: inline-block;
    padding: 0.7em 1.4em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 12px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #3369ff;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
    text-align: center;
    position: relative;
}

.btn-daclick:hover,
.btn-daclick-plus:hover,
.btn-daclick-secondary:hover,
.btn-daclick-danger:hover {
    color: #ffffff;
    background-color: #333333;
    border-color: #333333;
}

.btn-daclick-danger {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 10px 35px;
    font-family: 'rimouski-bold';
    border-radius: 19px;
}

.btn-daclick-plus {
    /* color: #fff;
  background-color: #27ae60;
  border-color: #27ae60;
  padding: 10px 35px;
  font-family: 'rimouski-bold';
  border-radius: 19px; */
    /* display: inline-block;
    padding: 0.46em 1.6em;
    border: 0.1em solid #fff;
    margin: 0 0.2em 0.2em 0;
    border-radius: 10px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #fff;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    background-color: #40bf9f;
    text-align: center;
    transition: all 0.15s; */
    display: inline-block;
    padding: 0.7em 1.4em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 12px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #27ae60;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
    text-align: center;
    position: relative;
}

.btn-daclick-secondary {
    /* background-color: #666;
    color: #fff;
    border-color: #666;
    padding: 10px 35px;
    font-family: 'rimouski-bold';
    border-radius: 19px; */
    display: inline-block;
    padding: 0.7em 1.4em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 12px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #666;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
    text-align: center;
    position: relative;
}


/* card style*/

.bkg-gray {
    background-color: #eeeeee;
    min-height: 85%
}

.bkg-gray-home {
    background-color: #eeeeee;
    min-height: 86%
}

.bkg-blue {
    background-color: #69b637;
}

.price {
    color: #222;
    line-height: 1.0;
    outline: 0 none;
    text-decoration: none;
    font-weight: 600;
    font-family: 'rimouski-bold';
}

.product-item-link {
    font-size: 14px;
    line-height: 1.0;
    outline: 0 none;
    text-decoration: none;
    font-weight: 600;
    color: #4b7ffc;
    text-decoration: none;
}

a {
    text-decoration: none !important;
}

.price-old {
    display: none;
}

.position-free-shipping {
    position: absolute;
    top: 1px;
    right: 0px;
}

.bkg-free-shipping {
    background: #fff;
    border-radius: 35px;
    border: 1px solid #6666;
    padding: 6px 3px 6px 5px;
    color: #69b637;
    opacity: 0.65;
}

.text-short {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.bkg-used {
    position: absolute;
    bottom: -7px;
    left: calc(100% - 50px);
    background-color: #fff;
    padding: 5px;
    border-radius: 10px 0 10px 0;
    font-size: 12px;
}

.bkg-new {
    position: absolute;
    bottom: -7px;
    left: calc(100% - 45px);
    background-color: #4b7ffc;
    padding: 5px;
    border-radius: 10px 0 10px 0;
    font-size: 12px;
}

.width-fitContent {
    width: fit-content;
}

.img-card {
    border-radius: 13px 13px 0 0 !important;
    overflow: hidden;
    padding-bottom: 10px;
}

@media screen and (min-width: 0px) and (max-width: 488px) {
    .btn-card-outstanding {
        width: 134px !important;
    }
    .btn-card {
        width: 144px !important;
    }
    .img-card {
        width: 116px;
        position: relative;
        min-height: 166px !important;
    }
    .maxHeight {
        max-height: 131px;
        width: auto;
    }
    .price {
        font-size: 16px;
    }
    .img-card>div>.overflow-hidden {
        max-height: 170px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 489px) {
    .btn-card {
        width: 202px;
    }
    .btn-card-outstanding {
        width: 202px;
    }
    .img-card {
        width: 180px;
        position: relative;
    }
    .maxHeight {
        max-height: 150px;
        width: auto;
    }
    .price {
        font-size: 20px;
    }
    .img-card>div>.overflow-hidden {
        max-height: 170px;
        height: 100%;
        min-height: 169px;
        display: flex;
        align-items: center;
    }
}

.title-daclick {
    border-bottom: 2px solid #4b7ffc;
    font-size: 20px;
    margin: 0;
}

.title-card-line {
    border-bottom: 2px solid #4b7ffc;
}

.btn-card {
    border: 0px;
    cursor: pointer;
    background-color: #FFF;
    border-radius: 13px;
    margin-bottom: 10px;
}

.position-free-shipping>.fa-star {
    color: #FFBF00;
    text-shadow: 1px 1px 10px #fff;
    padding: 3px 5px 5px 5px;
    margin-right: 2px;
}

.btn-card-outstanding {
    cursor: pointer;
    border-radius: 13px;
    background-color: #FFF;
    margin-bottom: 10px;
}

.btn-card-outstanding:hover {
    box-shadow: 0px 0px 20px #FFBF00;
}

.btn-card:hover {
    background-color: #fff;
    box-shadow: 2px 3px 15px -2px;
    color: #6666;
    border-radius: 13px;
}

.btn-card:hover>.price-old,
.btn-card:hover>.img-card>.w-100>.price-old,
.btn-card-outstanding:hover>.price-old,
.btn-card-outstanding:hover>.img-card>.w-100>.price-old {
    display: block;
}

.bkg-off {
    position: absolute;
    background-color: #4caf4f;
    padding: 5px;
    color: #fff;
    border-radius: 10px 0 10px 0;
    font-size: 12px;
    left: -1px;
    top: 0px;
}


/* modify styles bootstrap*/

.form-control {
    height: 43px;
}

.bg-secondary {
    background-color: #eee !important;
}

.bkg-daclick,
.page-item.active .page-link {
    background-color: #4b7ffc;
    color: #fff;
}

.bg-light {
    background-color: #fff !important;
}

.rounded {
    border-radius: 20px !important;
}

.shadow {
    box-shadow: 1px 1px 10px #6666 !important;
}

.base-daclick {
    box-shadow: 1px 1px 10px #6666;
    border-radius: 13px !important;
    background-color: #fff !important;
    margin-bottom: 2rem;
    padding: 2rem;
}


/*styles globals*/

.border-form {
    margin: 0;
    background-color: #fff;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
    min-height: 405px;
    padding: 25px 30px;
}

.title-global {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0px;
    color: #000;
    text-transform: uppercase;
}

.font-25px {
    font-size: 25px;
}

.font-22px {
    font-size: 22px;
}

.font-23px {
    font-size: 23px;
}

.font-20px {
    font-size: 20px;
}

.font-33px {
    font-size: 33px;
}

.font-36px {
    font-size: 36px;
}

.font-46px {
    font-size: 46px;
}

.font-80px {
    font-size: 80px;
}

.font-18px {
    font-size: 18px;
}

.font-16px {
    font-size: 16px;
}

.font-15px {
    font-size: 15px;
}

.font-25rem {
    font-size: 2.5rem
}

.font-13rem {
    font-size: 1.3rem
}

.text-black {
    color: #000;
}

.width-inherit {
    width: inherit;
}

.text-gray {
    color: #666;
}

.text-blue {
    color: #4b7ffc !important;
}

.text-yellow {
    color: #e5c332;
}

.text-darkblue {
    color: #69b637;
}

.labels-list {
    max-height: 180px;
    overflow: auto;
}

.aling-center {
    display: flex;
    align-items: center;
}


/* sidebar */

ng-sidebar-container {
    position: absolute !important;
    height: 0 !important;
}

.main-content {
    height: 100vh;
}

.active-sidebar:hover>.ng-sidebar__content {
    z-index: 1;
}

.z-index-main {
    z-index: 99;
}

.ng-sidebar--left {
    color: #666 !important;
    width: 280px;
    box-shadow: 1px 1px 10px #69b637;
    position: fixed !important;
    height: 100vh !important;
    background: #fff;
}

.ng-sidebar__content {
    background-color: transparent;
    position: fixed !important;
}


/* Zoom */

.ngxImageZoomContainer {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
}

.ngxImageZoomContainer>img {
    max-height: 600px;
    height: 100%;
}

.ngxImageZoomFullContainer {
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
}

.ngxImageZoomFullContainer:hover {
    background: #fff;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

.gu-hide {
    left: -9999px !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

app-nav>ng-sidebar-container>ng-sidebar .ng-sidebar--left {
    width: 250px !important;
    overflow: hidden !important;
    z-index: 999 !important;
}

app-nav>ng-sidebar-container>ng-sidebar>aside {
    background-color: #fff !important;
    overflow-x: hidden !important;
}


/*Chat*/

.mytext {
    border: 0;
    padding: 10px;
    background: whitesmoke;
}

table {
    border-radius: 12px;
    overflow: hidden;
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.text>p:first-of-type {
    width: 100%;
    margin-top: 0;
    margin-bottom: auto;
    line-height: 13px;
    font-size: 16px;
}

.text>p:last-of-type {
    width: 100%;
    text-align: right;
    color: silver;
    margin-bottom: -7px;
    margin-top: auto;
}

.text-l {
    color: #fff;
    float: left;
    padding-right: 10px;
}

.text-r {
    float: right;
    padding-left: 10px;
    background: whitesmoke !important
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    padding-right: 10px;
    border-radius: 20px;
    padding: 0px 0px 0px 10px !important
}

.macro {
    margin-top: 5px;
    width: 85%;
    border-radius: 5px;
    padding: 5px;
    display: flex;
}

.msj-rta {
    float: right;
    background: whitesmoke;
}

.msj {
    float: left;
    background: #4b7ffc;
}

.frame {
    background: #e0e0de;
    min-height: 400px;
    padding: 0;
    overflow: auto;
    margin-bottom: 51px;
}

.frame>div:last-of-type {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
}

body>div>div>div:nth-child(2)>span {
    background: whitesmoke;
    padding: 10px;
    font-size: 21px;
    border-radius: 50%;
}

body>div>div>div.msj-rta.macro {
    margin: auto;
    margin-left: 1%;
}

.frame>ul {
    width: 100%;
    list-style-type: none;
    padding: 18px;
    display: flex;
    flex-direction: column;
    top: 0;
    max-height: 400px;
    padding-bottom: 20px;
}

.msj:before {
    width: 0;
    height: 0;
    content: "";
    top: -5px;
    left: -14px;
    position: relative;
    border-style: solid;
    border-width: 0 13px 13px 0;
    border-color: transparent #4b7ffc transparent transparent;
}

.msj-rta:after {
    width: 0;
    height: 0;
    content: "";
    top: -5px;
    left: 14px;
    position: relative;
    border-style: solid;
    border-width: 13px 13px 0 0;
    border-color: whitesmoke transparent transparent transparent;
}

input:focus {
    outline: none;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d4d4d4;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #d4d4d4;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #d4d4d4;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #d4d4d4;
}

.img-circle {
    border-radius: 20px;
    width: 44px;
}

.pagination {
    position: absolute !important;
}

.min-height-33px {
    min-height: 33px;
}

.active-sidebar {
    height: 100% !important;
}

.active-sidebar>.ng-sidebar__content {
    height: 100% !important;
    z-index: 2;
}

.ng-sidebar__content {
    height: 0;
}

aside {
    z-index: 999 !important;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.modal-content {
    border-radius: 20px;
}

html,
body {
    height: 100%;
}


/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */